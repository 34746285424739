<template>
  <div class="henader">
    <slot>
      <div class="title">{{ title }}</div>
    </slot>
  </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
  computed: {
    ...mapState({
      title: state => state.mobile.title,
    }),
  },
};
</script>
<style scoped lang="less">
.henader {
  text-align: center;
  width: 32rem;
  // height: 8.36rem;
  flex: 0 0 5.36rem;
  // background: linear-gradient(226deg, #53dbff 0%, #00aad8 100%);
  background-color: #5cb6f7;
  display: flex;
  // justify-content: flex-end;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 1.71rem;
}
.title {
  font-size: 1.54rem;
  font-family: PingFang SC;
  font-weight: 600;
  color: #ffffff;
  margin: 0 auto;
  justify-self: center;
}
.img {
  width: 2.05rem;
  height: 2.05rem;
  margin-right: 2.05rem;
  background-size: contain;
}
.img-clock {
  margin-left: auto;
  background-image: url('~@/assets/image/mobile/idenx_icon_tx@2x.png');
}

.img-notice {
  margin-left: auto;
  background-image: url('~@/assets/image/mobile/icon_rsq_w.png');
}

.img-contacts {
  margin-left: 0;
  background-image: url('~@/assets/image/mobile/icon_help_w-1.png');
}
.img-add {
  margin-left: 0;
  color: #ffffff;
  font-size: 2.2rem;
}

.back-img {
  width: 0.97rem;
  height: 1.69rem;
  margin-left: 1.79rem;
  margin-right: auto;
  background-image: url('~assets/image/icon-progress/btn_back_white@2x.png');
  background-size: cover;
}
</style>
