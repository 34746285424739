<template>
  <div :class="['header-parent', topStyle ? topStyle : '']" @click.self="handleCommon">
    <Headers>
      <slot name="headers"></slot>
    </Headers>
    <div :class="['break-margin', mainStyle ? mainStyle : '']" @click.self="handleCommon">
      <slot></slot>
    </div>
  </div>
</template>
<script>
import Headers from '@/components/mobile/Header';
export default {
  props: ['topStyle', 'mainStyle'],
  data() {
    return {};
  },
  components: {
    Headers,
  },
  methods: {
    handleCommon() {
      this.$emit('handleCommon');
    },
  },
};
</script>
<style lang="less"></style>
